@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* ------ Add your CSS below this line (or @import your other CSS files). ------- */

@import "components/section";
@import "components/typography";
@import "components/button";
@import "components/game-carousel";
@import "components/animate";

@import "components/custom-card";
@import "components/promos";

@import "others/confetti";
@import "others/fx-shadows";

@import "daisyui/navbar";
@import "daisyui/promomodal";

html, body
{
    @apply bg-oil !important;
}