.navbar
{
    @apply top-0 z-50 fixed py-3 px-5;
}

.navbar::before
{
    content: '';
    @apply block left-0 top-0 w-full h-full absolute bg-oil-light bg-opacity-70 border-b border-black border-opacity-20 backdrop-blur-md opacity-0 transition-opacity;
}

.navbar.highlight::before
{
    @apply opacity-100;
}

.navbar .navbar-start
{
    @apply relative;
}

#activatablenav .navbar-end
{
    @apply md:inline-flex block md:static fixed;
    @apply bottom-0 left-0 w-full;
}

#activatablenav .navbar-end > .ctas
{
    @apply md:gap-2 md:px-0 md:py-0 xs:gap-6 xs:px-6 xs:py-6 2xs:gap-4 2xs:px-4 2xs:py-4 gap-3 px-3 py-3;
    @apply bg-oil md:bg-opacity-0 bg-opacity-90 inline-flex md:w-auto w-full;
}

#activatablenav .neon-btn
{
    @apply md:flex-none flex-1 col-span-1 2xs:text-lg text-sm px-2;
}
