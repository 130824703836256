.section
{
    @apply relative max-w-5xl mx-auto py-24 px-6;
}

.section.pt-0
{
    padding-top: 0px;
}

.section.pb-0
{
    padding-bottom: 0px;
}

.side-gradient-l
{
    @apply before:bg-gradient-to-r from-gold-700 before:absolute before:h-full before:w-1/2;
}