@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

h1
{
    @apply text-white mb-7 uppercase font-normal text-center drop-shadow-lg font-heading inline-block bg-black bg-opacity-20 rounded-full px-4 py-0 max-w-lg !important;
    text-shadow: 0px 0px 3px #000, 0px 0px 6px #000, 0px 0px 5px #000F21;
    -webkit-box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.2); 
    box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.2);
}

h1 span
{
    @apply sm:text-7xl xs:text-6xl text-5xl mb-7 !important;
}

h2
{
    @apply sm:text-6xl xs:text-5xl text-3xl text-white mb-7 uppercase font-heading font-bold;
}

.line-b
{
    @apply after:block after:w-24 after:h-1 after:bg-theme-500 after:rounded-full after:mt-4 after:mb-7;
}

.line-b.text-center
{
    @apply after:mt-4 after:mx-auto;
}

.pre-h
{
    @apply xs:text-2xl text-xl block text-theme-500 mb-4 font-bold font-heading uppercase;
    /* @apply before:inline-block before:w-16 before:h-1 before:bg-theme-500 before:mb-1.5 before:mr-6 before:rounded-full; */
}

.pre-h.red
{
    @apply text-red;
}

.section p
{
    @apply text-gray-300 xs:text-lg text-base font-heading;
}

.text-shadow
{
    text-shadow: 0px 0px 6px rgba(0,0,0,0.9);
}

.text-shadow-xl, .md\:text-shadow-xl
{
    text-shadow: 0px 0px 2px rgba(0,0,0,1), 
    0px 0px 4px rgba(0,0,0,1), 
    0px 0px 4px rgba(0,0,0,1), 
    0px 0px 4px rgba(0,0,0,1), 
    0px 0px 4px rgba(0,0,0,1);
}


@media (min-width: 768px)
{
    .md\:text-shadow-xl
    {
        text-shadow: none;
    }
}