.neon-btn
{
    @apply text-white px-5 py-2 text-lg relative uppercase no-underline overflow-hidden rounded-lg bg-theme-800 border-2 border-theme border-opacity-80 transition-all font-medium tracking-wider text-center;
}

.neon-btn.emphasis
{
    @apply bg-red-800 border-2 border-red;

    box-shadow: 0 0 5px #FF9E00,
        0 0 25px #FF9E00;
}

.neon-btn.line
{
    @apply px-1.5 py-0 2xs:max-w-[48px] max-w-[40px] 2xs:h-[48px] 2xs:w-[48px] h-[40px] w-[40px];
    background-color: #00c202 !important;
    border-color: #00c202 !important;
}

.neon-btn.line img
{
    @apply w-full block;
}

.neon-btn.hover
{
    @apply scale-110;
}

.neon-btn:hover
{
    @apply bg-theme-600;
}

.neon-btn.emphasis:hover
{
    @apply bg-red-600;
    box-shadow: 0 0 5px #FFB338,
        0 0 25px #FFB338,
        0 0 50px #FFB338,
        0 0 100px #FFB338;
}

.neon-btn span
{
    position: absolute;
    display: block;
}

.neon-btn span:nth-child(1)
{
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #54A4FF);
    animation: btn-anim1 1s linear infinite;
}

.neon-btn span:nth-child(2)
{
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #54A4FF);
    animation: btn-anim2 1s linear infinite;
    animation-delay: .25s
}

.neon-btn span:nth-child(3)
{
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #54A4FF);
    animation: btn-anim3 1s linear infinite;
    animation-delay: .5s
}

.neon-btn span:nth-child(4)
{
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #54A4FF);
    animation: btn-anim4 1s linear infinite;
    animation-delay: .75s
}



.neon-btn.emphasis span:nth-child(1)
{
    background: linear-gradient(90deg, transparent, #FFD28A);
}

.neon-btn.emphasis span:nth-child(2)
{
    background: linear-gradient(180deg, transparent, #FFD28A);
}

.neon-btn.emphasis span:nth-child(3)
{
    background: linear-gradient(270deg, transparent, #FFD28A);
}

.neon-btn.emphasis span:nth-child(4)
{
    background: linear-gradient(360deg, transparent, #FFD28A);
}

@keyframes btn-anim1
{
    0%
    {
        left: -100%;
    }

    50%,
    100%
    {
        left: 100%;
    }
}

@keyframes btn-anim2
{
    0%
    {
        top: -100%;
    }

    50%,
    100%
    {
        top: 100%;
    }
}

@keyframes btn-anim3
{
    0%
    {
        right: -100%;
    }

    50%,
    100%
    {
        right: 100%;
    }
}

@keyframes btn-anim4
{
    0%
    {
        bottom: -100%;
    }

    50%,
    100%
    {
        bottom: 100%;
    }
}