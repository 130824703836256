.custom-cards
{
    @apply grid 2xs:grid-cols-2 grid-cols-1 gap-6 mt-12;
}
.custom-card
{
    @apply col-span-1 sm:p-8 2xs:p-4 p-8 border border-white border-opacity-70 rounded-xl;
}

.custom-card .icon-container
{
    @apply border-4 border-gold border-opacity-50 rounded-full xs:p-6 p-2;
}

.custom-card .icon-border
{
    @apply bg-gold-800 border-2 border-gold-400 rounded-full p-1;
    @apply relative before:block before:w-3 before:h-3 before:absolute before:bg-gold before:rounded-full before:-left-2;
    @apply relative after:block after:w-2.5 after:h-2.5 after:absolute after:bg-red after:rounded-full after:-right-0.5 after:bottom-0;
}

.custom-card .content-text
{
    @apply text-white md:text-lg text-base font-semibold text-center md:mt-8 mt-4;
}
