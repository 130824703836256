.promomodal
{
    @apply bg-opacity-90;
}

.promomodal h4
{
    @apply w-full text-center text-oil md:text-2xl text-xl text-shadow mt-4;
}

.promomodal *
{
    @apply transition-opacity;
}

.promomodal-box
{
    @apply relative max-w-2xl md:p-12 md:pb-8 2xs:p-6 2xs:pb-4 p-3 bg-white bg-opacity-60 rounded-3xl mx-6 z-10;

    -webkit-box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.4); 
    box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.4);
}

.promomodal-content
{
    @apply md:-mt-32;
}

.promomodal-content #content
{
    @apply overflow-hidden rounded-md border-2 border-gold;

    -webkit-box-shadow: 0px 0px 25px 15px rgba(0, 0, 0, 0.4); 
    box-shadow: 0px 0px 25px 15px rgba(0, 0, 0, 0.4);
}

.promomodal-content #content img
{
    
}

.promomodal-close
{
    @apply relative max-w-2xl bg-gold-900 bg-opacity-90 z-0 text-center rounded-b-3xl mx-12;

    -webkit-box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.4); 
    box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.4);
}

.promomodal-close .close-btn
{
    @apply block hover:cursor-pointer fill-current text-white w-full pt-3 pb-3.5;

}

.promomodal-close .close-btn svg
{
    @apply md:w-5 md:h-5 w-3 h-3 mx-auto;
}