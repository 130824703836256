.game-carousel
{
    @apply h-[600px] overflow-hidden relative max-w-[128px] mx-auto;
    @apply before:z-[1] before:block before:w-full before:h-12 before:absolute before:top-0 before:left-0 before:bg-gradient-to-b before:from-theme-900;
    @apply before:z-[1] after:block after:w-full after:h-12 after:absolute after:bottom-0 after:left-0 after:bg-gradient-to-t after:from-theme-900;
}

.game-carousel .games
{
    @apply grid grid-cols-1 gap-12 relative py-12;
}

.game-carousel .game
{
    @apply relative;
}
