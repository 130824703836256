.promo-card
{
    @apply transition-all relative backdrop-blur-sm bg-gold bg-opacity-30 rounded-xl border border-gold-100 overflow-hidden p-3 hover:p-0 hover:scale-105 hover:!bg-red hover:!border-gold-500 hover:!bg-opacity-100;

    -webkit-box-shadow: 0px 0px 25px 15px rgba(0, 0, 0, 0.2); 
    box-shadow: 0px 0px 25px 15px rgba(0, 0, 0, 0.2);
}

.promo-card.active
{
    @apply bg-red bg-opacity-30 border-red-100;
}

.promo-card:hover .img-cont
{
    opacity: 0.7;
}

#promos-section.less-promos
{
    @apply overflow-hidden;
}

#promos-section.less-promos .promos-cont
{
    @apply mb-0;
}

.promo-card.promo-card-main
{
    @apply relative p-0 border-gold-100 border-2 overflow-hidden;

    -webkit-box-shadow: 0px 0px 25px 15px rgba(0, 0, 0, 0.4); 
    box-shadow: 0px 0px 25px 15px rgba(0, 0, 0, 0.4);
}

.promo-card .img-cont
{
    @apply transition-opacity relative z-10 block rounded-md overflow-hidden shadow-lg hover:cursor-pointer;
    /* @apply before:w-full before:h-full before:bg-black before:absolute before:block; */
}

.promo-card .img-cont *
{
    @apply !pointer-events-none;
    pointer-events: none !important;
    display: block;
}

.promos
{
    @apply grid xs:grid-cols-3 2xs:grid-cols-2 grid-cols-1 xs:gap-6 gap-4 mt-12;
}

#promos-section.single-promo .promos
{
    @apply grid-cols-1;
}

#promos-section.double-promos .promos
{
    @apply 2xs:grid-cols-2 grid-cols-1;
}

#promos-section.double-promos .promos .promo-card
{
    @apply col-span-1 row-span-1;
}

.promos .promo-card
{
    @apply col-span-1;
}

.promos .promo-card:nth-child(6n - 5), .promos .promo-card:nth-child(6n - 1)
{
    @apply xs:col-span-2 xs:row-span-2 col-span-1 row-span-1;
}